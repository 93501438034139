import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'

import { Page, Main, ColorWrap } from '../../components/StyledComponents'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PageHero from '../../components/Blocks/PageHero'
import IconRow from '../../components/Blocks/IconRow'
import SimpleCta from '../../components/Blocks/SimpleCta'
import Section from '../../components/Base/Section'
import ProfileAccordion from '../../components/Blocks/Pilot_Accordion'
import JoinPilotCta from '../../components/Blocks/JoinPilotCta'

import './index.css'

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "page-hero/pilot-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(relativePath: { eq: "page-hero/pilot-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({ data }) => {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 800px)',
    },
  ]

  const iconsData = [
    {
      id: 'step-one',
      colIcon: 'frontDesk',
      colTitle: 'Step One',
      colDesc:
        'Patients answer questions pertinent to their visit in the ChartMedic app for Android.',
    },
    {
      id: 'step-two',
      colIcon: 'providerAccessInterviews',
      colTitle: 'Step Two',
      colDesc:
        'Responses appear in the ChartMedic clinic portal, where providers can review information to get a full picture of what the patient is experiencing.',
    },
    {
      id: 'step-three',
      colIcon: 'providerCopyToEpic',
      colTitle: 'Step Three',
      colDesc:
        'Providers import chart-ready clinical information into Epic with a couple of clicks. No typing required!',
    },
  ]

  return (
    <Layout>
      <SEO title='Pilots' description='' />
      <Page>
        <Header />
        <Main>
          <div className='pilot-header'>
            <PageHero
              position={'pilotheader-text'}
              background={sources}
              height={600}
              title='Welcome to ChartMedic - a complete patient intake system to simplify and improve the practice of medicine.'
            />
          </div>
          <ColorWrap Color='white'>
            <IconRow columns={iconsData} />
          </ColorWrap>
          <JoinPilotCta
            title='Join our pilot to try ChartMedic at no cost 
                to your medical organization!'
            link='/get-started'
            linkTitle='Join the Pilot'
          />
          <Section center>
            <div className='pilot-sectoin'>
              <div className='section-title'>
                <h2>Watch our product tour!</h2>
              </div>
              <div className='how-it-works-video-container'>
                <iframe
                  className='how-it-works-video'
                  src='https://www.youtube.com/embed/tpIuQ2Bwgkc'
                  title='Watch our product tour'
                ></iframe>
              </div>
            </div>
          </Section>
          <ColorWrap Color='gray'>
            <div className='pilot-sectoin pilot-faq'>
              <div className='section-title'>
                <h2>Frequently Asked Question</h2>
              </div>
              <ProfileAccordion />
              <div className='faq-contact'>
                <h5>
                  Still have questions?{' '}
                  <Link to='/contact'>
                    <span>Contact Us</span>
                    <span>{'>'}</span>
                  </Link>
                </h5>
              </div>
            </div>
          </ColorWrap>
          <div className='profile-section bottom-cta'>
            <SimpleCta
              title='We would love to welcome your medical organization to our pilot.'
              link='/get-started'
              linkTitle='Join the Pilot'
            />
          </div>
        </Main>
      </Page>
      <Footer hiddenPilotCta={true} />
    </Layout>
  )
}
