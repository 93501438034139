import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from 'styled-components'
import { ButtonLink } from '../../StyledComponents'
import Title from '../../Base/Title'
import media from '../../../constants/media'

// ColorCta Components
export const SimpleCtaWrapper = styled.div`
  background-color: #e5f5f5;
  padding: 45px 0;
  text-align: center;
  h2 {
    font-weight: bolder;
    ${media.xxsmall`
      font-size: 3rem;
    `}
    ${media.medium`
      font-size: 3.5rem;
    `}
    ${media.large`
    font-size: 4rem;
    `}
  }
`
export const JoinCtaSection = styled.div`
  max-width: 40%;
  margin: 0 auto;
  ${media.xxsmall`
      max-width: 80%;
  `}
  ${media.medium`
    max-width: 60%;
  `}
  ${media.large`
      max-width: 50%;
  `}
`
export const JoinCtaContainer = styled.div`
  max-width: 100%;
`
export const Paragraph = styled.p`
  font-size: 22px;
  font-weight: 400;
  color: #000;
  text-align: center;
  margin: 1em 0 1em 0;
  ${media.xxsmall`
  font-size: 16px;
  `}
  ${media.medium`
    font-size: 18px;
  `}
  ${media.large`
  font-size: 20px;
  `}
`
export const HeadingBottom = styled.h5`
  font-size: 26px;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin: 0 0 1em 0;
  ${media.xxsmall`
  font-size: 20px;
  `}
  ${media.medium`
    font-size: 24px;
  `}
  ${media.large`
  font-size: 26px;
  `}
`

// End ColorCta Components

const JoinPilotCta = (props) => (
  <SimpleCtaWrapper>
    <JoinCtaSection>
      <ScrollAnimation animateIn='fadeInUp' animateOnce>
        <Title level={2} margined>
          {props.title}
        </Title>
        <JoinCtaContainer>
          <Paragraph>
            Apply to join ChartMedic’s pilot program! If selected, your
            organization will be eligible to use ChartMedic at a reduced cost in
            exchange for occasional feedback about your experience. The pilot
            program will last four to six months after enrollment and is open to
            emergency rooms, hospitalists, and urgent care clinics with 15 - 200
            employees.
          </Paragraph>
        </JoinCtaContainer>
        <JoinCtaContainer>
          <HeadingBottom>
            Space is limited for this pilot program, so apply now.
          </HeadingBottom>
        </JoinCtaContainer>
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeInUp' animateOnce>
        <ButtonLink to={props.link}>{props.linkTitle}</ButtonLink>
      </ScrollAnimation>
    </JoinCtaSection>
  </SimpleCtaWrapper>
)

export default JoinPilotCta

