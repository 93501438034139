import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import styled from 'styled-components'
import media from '../../../constants/media'

export const faqData = [
  {
    id: 'question-1',
    question: "Who's eligible?",
    answer:
      '<ul><li>Emergency Rooms, Urgent Care Clinics, and other medical providers</li><li>Employ 15-200 people</li><li>Operate in the United States</li></ul>',
  },
  {
    id: 'question-2',
    question: 'Why should we participate?',
    answer:
      '<p>To have early access to a product that will streamline your practice and make it easier for everyone in your organization to provide great care. ChartMedic’s streamlined approach to data collection frees up 4 to 6 minutes of patient-clinician interaction time during appointments. Your feedback will help refine ChartMedic, allowing providers to be more efficient while offering patients high-quality care.</p>',
  },
  {
    id: 'question-3',
    question: 'What will we need to do?',
    answer:
      '<p>Commit to a monthly 30 - 45 minute check-in with ChartMedic via Zoom to talk about your experience. Your check-in will serve as your “payment” for ChartMedic for the month. That’s it!</p>',
  },
  {
    id: 'question-4',
    question: 'How do we sign up?',
    answer:
      "<p>We will be accepting applications throughout 2022. Once selected, your organization’s ChartMedic account will be activated. To apply, fill out the web form on the  <a href='/get-started/'>Get Started page</a> and select that you are interested in applying for the pilot.</p>",
  },
  {
    id: 'question-5',
    question: 'How does payment work during and after the pilot?',
    answer:
      '<p>While our system will generate invoices, you will not be required to make payments on any bills generated during the pilot. The ChartMedic billing team will mark invoices as “Paid” on our side once monthly check-ins are completed. Following the pilot, you have the option to start paying invoices or cancel your membership.</p>',
  },
  {
    id: 'question-6',
    question: 'How much does ChartMedic cost?',
    answer:
      '<p>Information about our pricing is available on our website at the bottom of the benefits page</p>',
  },
  {
    id: 'question-7',
    question: 'How will we be trained?',
    answer:
      '<p>We have training information and FAQs worked into the system. If you have any issues your team can reach out to support. We also plan to schedule short onboarding calls with each of our new customers.</p>',
  },
]
export const QuestionComponent = ({ children, className }) => (
  <div className={className}>
    <h5>{children}</h5>
  </div>
)

export const AccordionItemWrapper = styled(AccordionItem)`
  padding: 1.5em 3em;
  background-color: #fff;
  margin-bottom: 0.5em;
  ${media.xxsmall`
     padding: 1.5em;
    `}
  ${media.small`
     padding: 1.5em 3em;
    `};
`

export const Question = styled(QuestionComponent)`
  cursor: pointer;
  h5 {
    font-size: 24px;
    font-weight: bold;
    color: #003da6;
    margin: 0;
    ${media.xxsmall`
      font-size: 18px;
    `}
    ${media.small`
      font-size: 24px;
    `}
  }
`

export const Answer = styled.div`
  font-size: 18px;
  margin: 0 0 0.3em 0;
  ${media.xxsmall`
  font-size: 16px;
 `}
  ${media.small`
  font-size: 18px;
  `}
  ul {
    margin: 0.5em 0 0 0;
    padding: 0 0 0 1.2em;
  }
  p {
    margin: 0.5em 0 0 0;
    ${media.xxsmall`
    font-size: 16px;
    `}
    ${media.small`
    font-size: 18px;
  `}
  }
  a {
    color: #003da6 !important;
  }
`

const ProfileAccordion = (props) => (
  <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
    {faqData.map(({ id, question, answer }) => (
      <AccordionItemWrapper key={id}>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Question>{question}</Question>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Answer dangerouslySetInnerHTML={{ __html: answer }} />
        </AccordionItemPanel>
      </AccordionItemWrapper>
    ))}
  </Accordion>
)

export default ProfileAccordion
